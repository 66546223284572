import './moreInfo.scss'

import Cards from '../benefitcards'
import React from 'react'
import Wave from '../wave'
import certificateIcon from '../../../static/images/careers/016-certificate_OB.svg'
import growthIcon from '../../../static/images/careers/005-growth_OB.svg'
import moneyIcon from '../../../static/images/careers/021-money_OB.svg'
import shieldIcon from '../../../static/images/careers/050-shield_OB.svg'
import { getStaticContentByIdentifier } from "../../services/misc.service";

export default (props) => {

  /*const cards = [
    {
      icon: moneyIcon,
      title: 'Competitive Salary',
      body: 'We seek to hire the best and brightest, so we expect to compensate accordingly.',
    },
    {
      icon: shieldIcon,
      title: 'Insurance Coverage',
      body: 'Orange Bees is your work family, but we want to help take care of you and your family. We offer excellent group medical, vision, dental, and Life AD&D insurance.',
    },
    {
      icon: certificateIcon,
      title: 'Employee Training',
      body: 'We write code in cutting edge stacks. Believe it or not, some of our Flutter or Elastic experts weren’t experts when they started here. We want our engineers to be versatile, so if there’s a new language or platform you want to dive into, we’ll help make that happen. Rest assured, we’ll make sure you’re current on relevant certifications and training too.',
    },
    {
      icon: growthIcon,
      title: 'Advancement Opportunities',
      body: 'We’re growing, and the appetite for our expertise is growing too. We want you to grow with us. We’ll do everything we can to provide the career trajectory and advancement that delivers opportunity and value for you and Orange Bees.',
    },
  ]; */

  return (
    <section className="padding-t-6 margin-t-6 ob-benefits-content">
      <Wave color="#f8f8f8" background="#ffffff" />
      <div className="row">
        <div className="text-center">
          <h2>{getStaticContentByIdentifier('careers.subtitle1', props.staticContent)}</h2>
          <Cards cards={props.careerBenefits} />
        </div>
        
      </div>
      <div className="equal-opportunity-content padding-t-6">
          <div className="row">
          <div className="text-center margin-b-6">
            <h2>{getStaticContentByIdentifier('careers.subtitle2', props.staticContent)}</h2>
            <p className="text-justify padding-l-3 padding-r-3">
            {getStaticContentByIdentifier('careers.content2', props.staticContent)}
                      </p>
          </div>
        </div>
      </div>
      <div className="ob-cta padding-t-3 padding-b-3">
        <div className="row padding-t-6 padding-b-6">
          <div className="col-xs-12 text-center">
            <h2 className="text-white">{getStaticContentByIdentifier('careers.CTA.content', props.staticContent)}</h2>
            <a className="btn" href="https://orangebees.easyapply.co/" target="_blank" rel="noopener noreferrer">
            {getStaticContentByIdentifier('careers.CTA.button', props.staticContent)}
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}