import "./cards.scss"

import React from "react"

export default (props) => {
    return (
        <section>
            <div className="row padding-t-6">
                {props.cards.map((benefit, index, array) => {
                    return (
                        <React.Fragment key={index}>
                            <div key={index} className="col-xs-12 col-sm-6 col-md-6 text-center margin-b-6 service-card">
                                <img src={process.env.filesBaseUrl+benefit.benefitIcon.publicUrl} alt={benefit.alt} className="fill"/>
                                <p className="title text-uppercase">{benefit.title}</p>
                                <p className="text-medium">{benefit.body}</p>
                            </div>
                        </React.Fragment>
                    )
                })}
            </div>
        </section>
    )
}