import React from "react";
import Layout from "../components/layout"
import Header from "../components/header"
// import CareerListing from "../components/career-listing"
import Footer from "../components/footer"
import Careers from "../components/careers/careersListings"
import Info from "../components/careers/moreInfo"
import Seo from "../components/seo2"
import { getSEOByIdentifier } from "../services/seo.service";

const CareersPage = props => {
  const staticContent = props.pageContext.staticContent;
  const staticImages = props.pageContext.staticImages;
  const careerBenefits = props.pageContext.careerBenefits;
  const SEOInfo = props.pageContext.SEOInfo;

  return (
    <Layout>
      <Seo SEOInfo={getSEOByIdentifier('careers', SEOInfo)}/>
      <Header />
      <main id="maincontent">
        <Careers staticContent={staticContent} staticImages={staticImages}/>
        {/* <div className="col-xs-12 col-gutter-lr text-center padding-t-6 margin-t-6">
          <h2>Career Opportunities</h2>
        </div>
        <ul>
          {
            isDataAvailable()
              ? (
                careers.map((x, i) => (
                  <li key={i}><CareerListing listing={x} /></li>
                ))
              )
              : (
                <li style={{ textAlign: 'center', padding: '1rem' }}>
                  No career opportunities at the moment, please check back later.
                </li>
              )
          }
        </ul> */}
        <Info staticContent={staticContent} careerBenefits={careerBenefits}/>
      </main>
      <Footer />
    </Layout>
  )
}

export default CareersPage;